import { KinesisClient, PutRecordCommand, PutRecordsCommand } from '@aws-sdk/client-kinesis';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { v4 as uuidv4 } from 'uuid';

export default class Kinesis {
  constructor(streamName) {
    this.client = new KinesisClient(this._connectionParams());
    this.streamName = streamName;
    this.partitionKey = uuidv4();
  }

  async send(data) {
    const enc = new TextEncoder();
    const command = new PutRecordCommand({
      Data: enc.encode(data),
      PartitionKey: this.partitionKey,
      StreamName: this.streamName,
    });
    const response = await this.client.send(command);
  }

  async send_bulk(records) {
    const enc = new TextEncoder();
    const kinesisRecords = records.map(e => {
      return {
        Data: enc.encode(e),
        PartitionKey: this.partitionKey,
      };
    });
    const command = new PutRecordsCommand({
      Records: kinesisRecords,
      StreamName: this.streamName,
    });
    const response = await this.client.send(command);
  }

  _connectionParams() {
    const connectionParams = {
      region: 'us-east-1',
    };
    if (ANALYTICS_CONFIG_KINESIS_ENDPOINT_OVERRIDE) {
      connectionParams['endpoint'] = ANALYTICS_CONFIG_KINESIS_ENDPOINT_OVERRIDE;
    }
    connectionParams['credentials'] = fromCognitoIdentityPool({
      identityPoolId: 'us-east-1:4ceae27e-21e9-42ea-bda1-34fd67459ead',
      clientConfig: {
        region: 'us-east-1',
      },
    });
    return connectionParams;
  }
}
