const POST_SUBMIT_ACTION = {
  SHOW_SUCCESS_MESSAGE: 'show_success_message',
  REDIRECT_TO_URL: 'redirect_to_url',
  HIDE_THE_FORM: 'hide_the_form',
};

export class FormBuilder {
  scriptUrl = 'https://www.tintup.com/app/dist/formBuilder-bundle.js';
  stylesUrl = 'https://www.tintup.com/app/dist/formBuilder.css';
  apiUrl = 'https://api.tintup.com';
  formSelector = 'div[data-team-id][data-form-id]';
  forms = null;
  initialized = false;
  scriptFetched = false;

  initVariables() {
    this.apiUrl = API_URL || this.apiUrl;
    this.scriptUrl = SCRIPT_URL || this.scriptUrl;
    this.stylesUrl = STYLES_URL || this.stylesUrl;
  }

  init() {
    this.initVariables();
    if (!this.initialized) {
      this.initialized = true;
      this.forms = document.querySelectorAll(this.formSelector);
      this._fetchReactFormScript();
    }
  }

  setLocale(locale) {}

  updateField(fieldName, options) {}

  afterSubmitAction({
    layout: {
      postSubmit: { url, action },
    },
    teamId,
    formId,
    ...data
  }) {
    if (!action) {
      return;
    }
    switch (action) {
      case POST_SUBMIT_ACTION.REDIRECT_TO_URL:
        if (url) {
          location.href = url;
        } else {
          console.warn('Missing url in post submit action');
        }
        break;
      case POST_SUBMIT_ACTION.HIDE_THE_FORM:
        const div = document.querySelector(`[data-form-id="${formId}"]`);
        div.style.display = 'none';

        break;
    }
  }

  _fetchReactFormScript() {
    const script = document.createElement('script');
    script.src = this.scriptUrl;
    script.async = true;
    script.onload = () => {
      this.scriptFetched = true;
      this.renderForms();
    };
    document.body.appendChild(script);
  }

  _getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
      return parts
        .pop()
        .split(';')
        .shift();
  };

  findConfigByElementId(elementId) {
    return (window.tintForms && window.tintForms[elementId]) || {};
  }

  renderForms() {
    document.querySelectorAll(this.formSelector).forEach(div => {
      let {
        teamId: configTeamId = undefined,
        formId: configFormId = undefined,
        locale: configLocale = undefined,
        values: configFormValues = undefined,
        events: configEvents = undefined,
      } = this.findConfigByElementId(div.id);
      let teamId = div.getAttribute('data-team-id') || configTeamId;
      let formId = div.getAttribute('data-form-id') || configFormId;
      let locale = div.getAttribute('data-form-locale') || configLocale || document.documentElement.lang;
      let formValues = div.getAttribute('data-form-values') || configFormValues;
      let onLoadUser = configEvents?.onAfterLoad ? configEvents.onAfterLoad : null;
      let onSubmitUser = configEvents?.onAfterSubmit ? configEvents.onAfterSubmit : null;
      let onSubmitFinal = function(data) {
        if (onSubmitUser) {
          if (!onSubmitUser?.(data)) return;
        }
        this.afterSubmitAction?.(data);
      };
      window.Tint?.tintFormBuilderRender?.({
        selector: `div[data-team-id="${teamId}"][data-form-id="${formId}"]`,
        stylesUrl: this.stylesUrl,
        teamId,
        formId,
        locale,
        formValues,
        apiUrl: this.apiUrl,
        tintAnonymousUid: this._getCookie('tint-anonymous-uid'),
        onAfterSubmit: onSubmitFinal.bind(this),
        onAfterLoad: onLoadUser?.bind(this),
      });
    });
  }
}
