import { FormBuilder } from './form-builder';
import TintEcommerceMeasure from './analytics/tint-ecommerce-measure';

if (typeof window !== 'undefined') {
  window.Tint = {
    TintEcommerceMeasure: TintEcommerceMeasure,
    FormBuilder: new FormBuilder(),
  };
  window.Tint.FormBuilder.init();
}
